<template>
    <div class="makers-page">
        <makers-render-elements v-for="element in [page]" :key="element.id" :element="element" :figma-id="fileKey"></makers-render-elements>
    </div>
</template>

<script>
    export default {
        props: ['page', 'fileKey'],
    }
</script>
