import Vue from "vue";
            import VueRouter from "vue-router";
        
import Home from "../views/Home.vue"

            Vue.use(VueRouter);

            const routes = [
        

                {
                    path: "/",
                    name: "Home",
                    component: Home,
                    meta: { title: "Dra. Mafalda Cleto • Medicina Estética", description: "A Dra. Mafalda Cleto é especialista em medicina estética.", image: "https://zecoda.nyc3.digitaloceanspaces.com/makers/u/gh2f1iUa19EcB1dUi8fRi/2dc86597-6a9b-4516-8e21-3639c276a6bb.png" }
                },
            

            ];

            const router = new VueRouter({
                mode: "history",
                base: process.env.BASE_URL,
                routes
            });

            export default router;