import Vue from "vue";
            import App from "./App.vue";
            import store from "./store";
            import router from "./router";
            import VueYoutube from "vue-youtube";
            import smoothscroll from "smoothscroll-polyfill";

            Vue.use(VueYoutube);
            smoothscroll.polyfill();

            const files = require.context("./", true, /\.vue$/i);
            files.keys().map(key => Vue.component(key.split("/").pop().split(".")[0], files(key).default));

            new Vue({
                store,
                router,
                render: h => h(App)
            }).$mount("#app");

            // Smooth scroll
            document.querySelectorAll("a[href^='#']").forEach(anchor => {
                anchor.addEventListener("click", function (e) {
                    e.preventDefault();
                    document.querySelector(this.getAttribute("href")).scrollIntoView({
                        behavior: "smooth"
                    });
                });
            });