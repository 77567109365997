<script>
    import { mapState } from 'vuex';
    import Style from '../../helpers/style';
    import Element from '../../helpers/element';
    import { getImageUrlFromBucket } from '../../helpers/utilities';
    export default {
        props: {
            element: Object,
            figmaId: String,
        },
        render(createElement) {
            return this.renderElement(createElement, this.element);
        },
        computed: {
            ...mapState(['isTesting', 'imagesUrls']),
        },
        methods: {
            renderElement(createElement, element, parent) {
                if (element.type === 'VECTOR') { return; }
                if (element.type === 'INSTANCE' && element.makersComponent === 'switch') { return createElement('makers-switch', {props:{node: element}}); }
                if (element.makersComponent === 'navbar') { return createElement('makers-navbar', {props: {node: element, figmaId: this.figmaId, selectedPage: this.selectedPage} }); }
                const data = Element.getElementData(element, this.figmaId);
                const htmlTag = this.getElementHtmlTag(element, data);
                if (parent != null) {this.getElementSpacing(data, parent)}
                if (element.children.length) {
                    let parent = (element.itemSpacing && element.primaryAxisAlignItems !== 'SPACE_BETWEEN') ? {margin: element.itemSpacing, direction: element.layoutMode, width: element.width} : {};
                    parent.classes = element.makersClasses;
                    return createElement(htmlTag, data, element.children.map((el, i) => {
                        if (parent != null ) parent.index = i+1;
                        parent.children = element.children.length;
                        return this.renderElement(createElement, el, parent);
                    }));
                } else {
                    const textObject = (element.makersText != "") ? JSON.parse(element.makersText) : null;
                    if (textObject != null) {
                        if (textObject.fills.length) {
                            return createElement(htmlTag, data, textObject.fills.map(el => {
                                const style = new Style(el, 'fills');
                                const nodeText = element.characters.substring(el.from, el.to);
                                const elData = {style: style.getMixedTextStyles(),domProps: {innerHTML: nodeText.replaceAll(/\n/g, "<br />")}}
                                return createElement('span', elData, nodeText);
                            }));
                        }
                        if (textObject.fontSize.length) {
                            return createElement(htmlTag, data, textObject.fontSize.map(el => {
                                const style = new Style(el, 'fontSize');
                                const nodeText = element.characters.substring(el.from, el.to);
                                const elData = {style: style.getMixedTextStyles(),domProps: {innerHTML: nodeText.replaceAll(/\n/g, "<br />")}}
                                return createElement('span', elData, nodeText);
                            }));
                        }
                        if (textObject.fontName.length) {
                            return createElement(htmlTag, data, textObject.fontName.map(el => {
                                const style = new Style(el, 'fontName');
                                const nodeText = element.characters.substring(el.from, el.to);
                                const elData = {style: style.getMixedTextStyles(),domProps: {innerHTML: nodeText.replaceAll(/\n/g, "<br />")}}
                                return createElement('span', elData, nodeText);
                            }));
                        }
                        if (textObject.textCase.length) {
                            return createElement(htmlTag, data, textObject.textCase.map(el => {
                                const style = new Style(el, 'textCase');
                                const nodeText = element.characters.substring(el.from, el.to);
                                const elData = {style: style.getMixedTextStyles(), domProps: {innerHTML: nodeText.replaceAll(/\n/g, "<br />")}}
                                return createElement('span', elData, nodeText);
                            }));
                        }
                        if (textObject.hyperlink.length) {
                            return createElement(htmlTag, data, textObject.hyperlink.map(el => {
                                const style = new Style(el, 'hyperlink');
                                const nodeText = element.characters.substring(el.from, el.to);
                                if (el.hasOwnProperty('value')) {
                                    const elData = {style: {'text-decoration': 'underline'}, attrs: style.getMixedTextStyles(), domProps: {innerHTML: nodeText.replaceAll(/\n/g, "<br />")}}
                                    return createElement('a', elData, nodeText);
                                } else {
                                    const elData = {style: style.getMixedTextStyles(), domProps: {innerHTML: nodeText.replaceAll(/\n/g, "<br />")}}
                                    return createElement('span', elData, nodeText);
                                }
                            }));
                        }
                    }
                    if (element.characters) data.domProps = {innerHTML: element.characters.replaceAll(/\n/g, "<br />")};
                    return createElement(htmlTag, data, element.characters);
                }
            },
            getElementHtmlTag(element, data) {
                // Check if element has an IMAGE fill && doesn't have children
                    // If true, make it <img /> with attributes of src="this.imagesUrls[element.id]";
                    // Else, return element.makersHtmlTag || 'div';
                const hasImageFillAndNoChildren = this.hasImageFillAndNoChildren(element);
                if (hasImageFillAndNoChildren) {
                    data.attrs = {src: getImageUrlFromBucket(this.figmaId, element.fills[0].imageHash)};
                    data.style.width = "100%";
                    data.style['object-fit'] = (element.fills[0].scaleMode === "FIT") ? "contain" : "cover";
                    return 'img';
                } else {
                    return element.makersHtmlTag || 'div';
                }
            },

            hasImageFillAndNoChildren(element) {
                if (element.fills == null) return false;
                const hasImage = (element.fills.find(paint => paint.type === 'IMAGE')) ? true : false;
                const hasChildren = element.children.length > 0;
                return (hasImage && !hasChildren);
            },
            getElementSpacing(data, parent) {
                // Add margins (bottom or right) if parent element has itemSpacing defined
                // This is to avoid gap in flex elements - gap not supported in flex yet.
                this.getElementWidthForColumnChildren(data, parent);

                if (parent.index === parent.children) return;

                (parent.direction === "VERTICAL") ?
                    data.style['margin-bottom'] = `${parent.margin}px` :
                    data.style['margin-right'] = `${parent.margin}px`;

                return;
            },
            getElementWidthForColumnChildren(data, parent) {
                if (parent.classes == null)  return;
                const isParentColumns = parent.classes.replaceAll(" ", "").split(',').find(c => c === 'columns');
                if (isParentColumns) {
                    const maxWidth = Number(data.style["max-width"].replaceAll("px", ""));
                    const percentageWidth = Math.round(maxWidth / parent.width * 100);
                    data.style.width = `${percentageWidth}%`;
                }
            }
        },

    }
</script>
