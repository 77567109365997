/********************************/
/* URL operations */
/********************************/
export const getUrlQueryStringValues = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
}

export const getUrlQueryStringByValue = (value) => {
    return new URLSearchParams(window.location.search).get(value);
}

export const getImageUrlFromBucket = (figmaId, imageHash) => {
    const endpoint = "https://zecoda.nyc3.digitaloceanspaces.com/makers/u";
    return `${endpoint}/${figmaId}/images/${imageHash}`;
}

/********************************/
/* DOM operations */
/********************************/
export const setCssCustomProperty = (element, property, value) => {
    element.style.setProperty(property, value);
}

/********************************/
/* Object operations */
/********************************/
export const sortArrayOfObjectsByValue = (array, value) => {
    return array.sort( (a, b, value ) => (a[value] > b[value]) ? 1 : ((b[value] > a[value]) ? -1 : 0));
}

/********************************/
/* String operations */
/********************************/
export const camelCaseTo = (string, separator) => {
    return string.replace(/[A-Z]/g, letter => `${separator}${letter.toLowerCase()}`);
}

// export default {
//     getUrlQueryStringValues: () => getUrlQueryStringValues(),
//     camelCaseTo: (string, separator) => camelCaseTo(string, separator),
//     getUrlQueryStringByValue: (value) => getUrlQueryStringByValue(value),
//     sortArrayOfObjectsByValue: (array, value) => sortArrayOfObjectsByValue(array, value),
//     setCssCustomProperty: (element, property, value) => setCssCustomProperty(element, property, value),
// }
