import Vue from "vue";
            import Vuex from "vuex";

            Vue.use(Vuex);

            export default new Vuex.Store({
                modules: {},
                state: {
                    imagesUrls: {},
                    imageNodes: [],
                },
                mutations: {
                    setImageNodes(state, nodeId) {
                        state.imageNodes.push(nodeId);
                    },
                    setImagesUrls(state, urls) {
                        state.imagesUrls = urls;
                    }
                },
            })