import matrixInverse from 'matrix-inverse';
import { getImageUrlFromBucket } from '../helpers/utilities';

export default class Style {

    constructor(element, type) {
        this.element = element;
        this.type = type;
    }

    get fontFamily() {
        return this.getFontFamily();
    }
    get fontSize() {
        return this.getFontSize();
    }
    get fontWeight() {
        return this.getFontWeight();
    }
    get lineHeight() {
        return this.getLineHeight();
    }
    get letterSpacing() {
        return this.getLetterSpacing();
    }

    get color() {
        return this.getColor();
    }

    get background() {
        return this.getBackground();
    }

    get getMixedTextStyles() {
        return this.getMixedTextStyles();
    }

    /*******************************************
    | Color
    *******************************************/
    getColor() {
        for (const paint of this.element.fills) {
            if (paint.type === 'SOLID') {
                return [{'background-color': this.getRgbaColor(paint.color, paint.opacity)}];
            }
        }
    }
    /*******************************************
    | Typography
    *******************************************/
    getFontFamily() {
        return `${this.element.fontName.family}, sans-serif`;
    }
    getFontSize() {
        return `${Math.round(this.element.fontSize)}px`;
    }
    getFontWeight() {
        return this.getFontWeightString(this.element.fontName.style);
    }
    getLineHeight() {
        return `${this.element.lineHeight.value}${this.getUnitString(this.element.lineHeight.unit)}`;
    }
    getLetterSpacing() {
        return `${(this.element.letterSpacing.unit === 'PERCENT') ? this.element.letterSpacing.value / 100 : this.element.letterSpacing.value }${this.getUnitStringForLetterSpacing(this.element.letterSpacing.unit)}`;
    }
    getFontColor() {
        if (this.element.fills == null) { // When fills is null it means it's a mixed object
            return [];
        }
        for (const paint of this.element.fills) {
            if (paint.type === 'SOLID') {
                return [{color: this.getRgbaColor(paint.color, paint.opacity)}];
            } else if (paint.type === 'GRADIENT_LINEAR' || paint.type === 'GRADIENT_DIAMOND') {
                return [
                    {background: this.getLinearGradient(paint) },
                    {'-webkit-text-fill-color': 'transparent'},
                    {'-webkit-background-clip': 'text'},
                    {'background-clip': 'text'},
                ];
            }
        }
    }

    /*******************************************
    | Borders
    *******************************************/
    getBorder() {
        if (_.has(this.element, 'strokes') === false) return;
        if (this.element.strokes.length === 0) return;
        const hasSolidColor = this.element.strokes[0].type === 'SOLID';
        let color, style;
        if (hasSolidColor) {
            color = this.getRgbaColor(this.element.strokes[0].color, 1);
            style = 'solid';
        }
        return `${this.element.strokeWeight}px ${style} ${color}`;
    }

    /*******************************************
    | Shadows
    *******************************************/
    getShadows() {
        const shadows = [];
        let blur = ``;

        this.element.effects.forEach((effect) => {
            if (effect.type === "DROP_SHADOW" || effect.type === "INNER_SHADOW") {
                shadows.push(this.generateShadowStyle(effect)); // can have multiple shadows on a single element
            } else if (effect.type === "LAYER_BLUR") {
                blur = `blur(${effect.radius}px)`; // can only have one blur-filter. Note: background_blur is not implemented in all browsers, so wont use that type of effect
            }
        });

        const result = {
            shadows: `${shadows}`,
            blur: blur,
        };

        return result;
    }
    generateShadowStyle({ type, color, offset, radius }) {
        const rgba = this.getRgbaColor(color);
        return `${type === "INNER_SHADOW" ? "inset" : ""} ${offset.x}px ${offset.y}px ${radius}px ${rgba}`;
    }

    /*******************************************
    | Backgrounds
    *******************************************/
    getBackground() {
        if (_.has(this.element, 'fills') == false) return;
        if (this.element.fills.length === 0) return;

        for (const paint of this.element.fills) {
            if (paint.type === 'SOLID') {
                return [
                    {'background-color': this.getRgbaColor(paint.color, paint.opacity)}
                ];
            } else if (paint.type === 'IMAGE') {
                // If this element doesn't have children,
                // We will add as ‹img/> instead of background-image
                if (this.element.children.length > 0) {
                    return this.getBackgroundImage(paint);
                }
            } else if (paint.type === 'GRADIENT_LINEAR' || paint.type === 'GRADIENT_DIAMOND') {
                return [ { background: this.getLinearGradient(paint) } ];
            } else if (paint.type === 'GRADIENT_RADIAL') {
                console.log("GRADIENT_RADIAL paint ", paint);
            } else if (paint.type === 'GRADIENT_ANGULAR') {
                console.log("GRADIENT_ANGULAR paint ", paint);
            }
        }
    }
    getBackgroundImage(paint) {
        console.log("this.type", this.type);
        return [
            {'background-image': `url(${getImageUrlFromBucket(this.type, this.element.fills[0].imageHash)})`},
            {'background-repeat': 'no-repeat'},
            {'background-position': 'center'},
            {'background-size': 'cover'},

            {'width': '100%'},
            {'max-width': `${this.element.width}px`},
            {'min-height': `${this.element.height}px`},
        ];
    }
    getLinearGradient(paint) {
        // 1. Get Linear Gradient Start and End handle (x, y)
        const points = this.getLinearGradientPoints(paint.gradientTransform);
        // console.log("points", points);
        // 2. Calculate Angle
        const angle = this.calculateLinearGradientAngle(points.start, points.end);
        // console.log("angle", angle);
        // 3. Add Gradients
        const gradients = this.getGradientsColorsAndPositions(paint.gradientStops, points);
        // console.log("gradients", gradients);
        // Return string linear-gradient(angle, gradient position, *n)
        return `linear-gradient(${angle}deg, ${gradients})`;
    }
    getRadialBackground() {}
    getBackgroundSize() {}

    /*
    |-------------------------------------
    | MIXED STYLES
    |-------------------------------------
    */
    getMixedTextStyles() {
        let obj = {};
        let typography = [];
        const value = (this.type === 'fills') ? JSON.parse(this.element.value) : this.element.value;
        switch (this.type) {
            case 'fills':
                for (const paint of value) {
                    if (paint.type === 'SOLID') {
                        typography.push({color: this.getRgbaColor(paint.color, paint.opacity)});
                    } else if (paint.type === 'GRADIENT_LINEAR' || paint.type === 'GRADIENT_DIAMOND') {
                        typography.push(
                            {'background-image': this.getLinearGradient(paint)},
                            {'-webkit-text-fill-color': 'transparent'},
                            {'-webkit-background-clip': 'text'},
                            {'background-clip': 'text'},
                        );
                    }
                }
                break;
            case 'fontName': typography.push({'font-family': `${JSON.parse(value).family}, sans-serif`}, {'font-weight': this.getFontWeightString(JSON.parse(value).style)}); break;
            case 'fontSize': typography.push({'font-size': `${value}px`}); break;
            case 'textCase': typography.push({'text-transform': this.getTextCaseString(value)}); break;
            // hyperlink: createRanges(Object.values(hyperlink)),
            // lineHeight: createRanges(Object.values(lineHeight)),
            // textStyleId: createRanges(Object.values(textStyleId)),
            // fillStyleId: createRanges(Object.values(fillStyleId)),
            // paragraphIndent: createRanges(Object.values(fontSize)),
            // textDecoration: createRanges(Object.values(textDecoration)),
            // textListOptions: createRanges(Object.values(textListOptions)),
        }

        typography.forEach(v => Object.assign(obj, v));
        return obj;
    }

    /*******************************************
    | Helpers
    *******************************************/
    getRgbaColor(color, opacity) {
        const alpha = (color.a) ? Math.round(color.a * 100) / 100 : (opacity != null) ? Math.round(opacity * 100) / 100 : '1';
        return `rgba(${this.normalizeColor(color.r)}, ${this.normalizeColor(color.g)}, ${this.normalizeColor(color.b)}, ${alpha})`;
    }
    normalizeColor(color) {
        return Math.round(color * 255);
    }
    getFontWeightString(value) {
        switch (value) {
            case "Thin": return 100;
            case "Extra Light": return 200;
            case "Light": return 300;
            case "Regular": return 400;
            case "Medium": return 500;
            case "Semi Bold": return 600;
            case "Bold": return 700;
            case "Extra Bold": return 800;
            case "Black": case "Heavy": return 900;
        }
    }
    getTextCaseString(value) {
        switch (value) {
            case '"UPPER"': return "uppercase";
            case '"LOWER"': return "lowercase";
            case '"TITLE"': return "capitalize";
        }
    }
    getUnitString(unit) {
        switch (unit) {
            case "PIXELS": return "px";
            case "PERCENT": return "%";
        }
    }
    getUnitStringForLetterSpacing(unit) { // CSS doesn't accept %
        switch (unit) {
            case "PIXELS": return "px";
            case "PERCENT": return "em";
        }
    }
    getLinearGradientPoints(t) {
        const shapeWidth = this.element.width || 100;
        const shapeHeight = this.element.height || 60;
        const transform = t.length === 2 ? [...t, [0, 0, 1]] : [...t];
        const mxInv = matrixInverse(transform);
        const startEnd = [
            [0, 0.5],
            [1, 0.5]
        ].map((p) => this.applyMatrixToPoint(mxInv, p));
        return {
            start: {x: Math.round(startEnd[0][0] * shapeWidth), y: Math.round(startEnd[0][1] * shapeHeight)},
            end: {x: Math.round(startEnd[1][0] * shapeWidth), y: Math.round(startEnd[1][1] * shapeHeight)}
        }
    }
    getGradientsColorsAndPositions(gradients, points) {
        const g = [];
        const maxY = Math.max(points.start.y, points.end.y); // To make the gradient stop
        gradients.forEach(gradient => g.push(`${this.getRgbaColor(gradient.color)} ${Math.round((gradient.position * (maxY / this.element.height || 60)) * 100)}%`));
        return g.join(', ');
    }
    applyMatrixToPoint(matrix, point) {
        return [
            point[0] * matrix[0][0] + point[1] * matrix[0][1] + matrix[0][2],
            point[0] * matrix[1][0] + point[1] * matrix[1][1] + matrix[1][2]
        ]
    }
    calculateLinearGradientAngle(startPoint, endPoint) {
        const radians = this.calculateRadians(startPoint, endPoint);
        return this.radToDeg(radians);
    }
    calculateRadians(startPoint, endPoint) {
        return Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);
    }
    radToDeg(radian) {
        return radian * 180 / Math.PI + 90;
    }
}
