<template>
    <section
        class="makers-navbar"
        :class="{
            show: isMobileMenuActive,
            hide: !isMobileMenuActive,
            'mobile-menu-wrapper': isMobileMenuActive
        }"
    >
        <makers-render-elements v-for="element in node.children" :key="element.id" :element="element" :figmaId="figmaId"></makers-render-elements>
        <div
            class="mobile-trigger"
            @click="isMobileMenuActive = !isMobileMenuActive"
        >
            <div :class="{ open: !isMobileMenuActive, close: isMobileMenuActive }"><span></span><span></span><span></span></div>
        </div>
    </section>
</template>
<script>
    const DESKTOP_WIDTH = 992;
    export default {
        props: ['node', 'figmaId'],
        data() {
            return {
                isMobileMenuActive: false,
            }
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.onResize);
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener("resize", this.onResize);
            });
        },
        methods: {
            onResize() {
                if (window.innerWidth > DESKTOP_WIDTH) {
                    this.isMobileMenuActive = false;
                }
            },
        }
    }
</script>
