<template>
                <div id="app">
                    <router-view />
                </div>
            </template>
            <script>
                export default {
                    watch: {
                        "$route": {
                            handler: (to) => {
                                document.title = to.meta.title || "Build and launch websites with Figma";
                            },
                            immediate: true
                        }
                    },
                }
            </script>