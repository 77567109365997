import _ from "lodash";
import Style from "../helpers/style";
import { camelCaseTo } from "../helpers/utilities";

export default class Element {

    constructor(element, figmaId) {
        this.element = element;
        this.figmaId = figmaId;
    }

    static getElementData(element, figmaId) { // Static means we don't have to instanciate the object
        const styles = this.getStyles(element, figmaId);
        const classes = this.getClasses(element);
        const attr = this.getAttr(element);
        return {
            class: classes,
            style: styles,
            attrs: attr,
        }
    }

    static getClasses(element) {
        if (element.makersClasses === "") return;
        const classes = (element.makersClasses === null) ? [] : element.makersClasses.replaceAll(" ", "").split(',');
        return classes;
    }
    static getAttr(element) {
        if (element.makersAttrs === "" || element.makersAttrs === null) return;
        return JSON.parse(element.makersAttrs);
    }
    static getStyles(element, figmaId) {
        const obj = {};
        this.getPadding(element).forEach(v => Object.assign(obj, v));
        this.getSize(element).forEach(v => Object.assign(obj, v));
        // this.getOther(element);
        this.getLayout(element).forEach(v => Object.assign(obj, v));
        this.getBorders(element).forEach(v => Object.assign(obj, v));
        this.getBorderRadius(element).forEach(v => Object.assign(obj, v));
        this.getShadows(element).forEach(v => Object.assign(obj, v));
        this.getTypography(element).forEach(v => Object.assign(obj, v));
        // this.getTransforms(element);
        this.getBackgrounds(element, figmaId).forEach(v => Object.assign(obj, v));
        return obj;
    }

    static getVectorElementsData(element) {
        // console.log("element", element);
    }

    static getPadding(element) {
        const returnedPadding = [];
        const paddings = ['paddingLeft', 'paddingRight', 'paddingTop', 'paddingBottom'];
        paddings.forEach(padding => {
            if (element[padding] != null && element[padding] != 0) {
                let obj = {}; obj[camelCaseTo(padding, '-')] = `${element[padding]}px`;
                returnedPadding.push(obj);
            }
        });
        return returnedPadding;
    }
    static getSize(element) {
        if (element.type === "TEXT") return [{'max-width': `${element.width + 10}px`}];
        else if (element.type === "RECTANGLE") return [{'max-width': `${element.width}px`}, {'width': '100%'}, {'height': `${element.height}px`}];
        // const isCustomElement = this.getIsCustomElement(element);
        return [];
        // const isCustomElement = false;
        // if (isCustomElement) return [];
        // const sizes = [];
        // if (hasNavItems) sizes.push({width: `${element.width}px`});
        // if (hasNav) sizes.push({'max-width': `${element.width}px`});
        // if (hasInput) sizes.push({'min-width': `${element.width}px`});
        // return sizes;
    }
    static getOther(element) {}
    static getLayout(element) {
        if (!element.visible) return [{display: 'hidden'}];
        if (element.layoutMode === "NONE" || _.has(element, 'layoutMode') === false) return [];
        const layouts = [{display: 'flex'}];
        return [...layouts, ...this.handleFlexLayout(element)];
    }
    static getBorderRadius(element) {
        if (_.has(element, 'cornerRadius')) {
            if (element.cornerRadius != 0) {
                return [ {'border-radius': `${element.cornerRadius}px`} ];
            }
        }
        const returnedRadius = [];
        const radii = ['topLeftRadius', 'topRightRadius', 'bottomLeftRadius', 'bottomRightRadius'];
        radii.map(radius => {
            if (element[radius] != null && element[radius] != 0) {
                let obj = {}; obj[`border-${camelCaseTo(radius, '-')}`] = `${element[radius]}px`;
                returnedRadius.push(obj);
            }
        });
        return returnedRadius;
    }
    static getBorders(element) {
        const style = new Style(element);
        const border = style.getBorder();
        return [
            {border},
        ]
    }
    static getShadows(element) {
        const style = new Style(element);
        const effects = style.getShadows();
        return [
            {'box-shadow': effects.shadows}
        ];
    }
    static getTypography(element) {
        if (element.type !== 'TEXT') return [];
        const typography = [];
        const style = new Style(element);
        const fontColorProps = style.getFontColor();
        fontColorProps.forEach( prop => typography.push(prop) );
        if (element.fontSize) typography.push({'font-size': style.getFontSize()});
        if (element.lineHeight.unit !== "AUTO") typography.push({'line-height': style.getLineHeight()});
        if (element.letterSpacing.unit !== "AUTO") typography.push({'letter-spacing': style.getLetterSpacing()});
        if (element.fontName) {
            typography.push({'font-family': style.getFontFamily()});
            typography.push({'font-weight': style.getFontWeight()});
        }
        switch (element.textAlignHorizontal) {
            case "RIGHT": typography.push({'text-align': 'right'}); break;
            case "CENTER": typography.push({'text-align': 'center'}); break;
            case "JUSTIFIED": typography.push({'text-align': 'justify'}); break;
        }

        return typography;
    }
    static getTransforms(element) {}
    static getBackgrounds(element, figmaId) {
        if (element.type === 'TEXT') return [];
        const style = new Style(element, figmaId);
        const background = style.getBackground();
        if (background == null) return [];
        return background;
    }

    /*********************************
    | HELPERS
    **********************************/
    static handleFlexLayout(element) {
        const layout = [];
        switch(element.layoutMode) {
            case "VERTICAL": layout.push({'flex-direction': 'column'}); break;
        }
        switch (element.primaryAxisAlignItems) {
            case "CENTER": layout.push({'justify-content': 'center'}); break;
            case "MAX": layout.push({'justify-content': 'flex-end'}); break;
            case "SPACE_BETWEEN": layout.push({'justify-content': 'space-between'}); break;
        }
        switch (element.primaryAxisSizingMode) {
            case "FIXED": case "AUTO": layout.push({'max-width': `${element.width}px`}, {'width': '100%'}); break;
        }
        switch (element.counterAxisAlignItems) {
            case "CENTER": layout.push({'align-items': 'center'}); break;
            case "MAX": layout.push({'align-items': 'flex-end'}); break;
        }
        switch(element.counterAxisSizingMode) {
            case "FIXED": layout.push({'min-height': `${element.height}px`}); break;
        }
        switch(element.layoutAlign) {
            case "STRETCH": layout.push({width: '100%'}); break;
        }

        // if (element.itemSpacing && element.primaryAxisAlignItems !== 'SPACE_BETWEEN') layout.push({gap: `${element.itemSpacing}px`});

        return layout;
    }
    static getIsCustomElement(element) {
        const hasNavItems = element.makersClasses.replaceAll(" ", "").split(',').find(c => c === 'nav-items');
        const hasNav = element.makersClasses.replaceAll(" ", "").split(',').find(c => c === 'nav');
        const hasInput = element.makersClasses.replaceAll(" ", "").split(',').find(c => c === 'input-wrapper');
        if (!hasNavItems && !hasNav && !hasInput) return true;
    }
}
